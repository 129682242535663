/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesSpike: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Primal Lord - Mirage Frostspike</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_primalspike.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Primal Lord - Mirage Frostspike</h1>
          <h2>
            Primal Lord - Mirage Frostspike guide that gives you the best teams
            to use against the boss.
          </h2>
          <p>
            Last updated: <strong>24/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Mirage Frostspike" />
        <p>
          <strong>Mirage Frostspike</strong> is one of the two Primal Lords
          introduced in the Season 3 of AFK Journey. In this guide you will find
          the best teams to use against him!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Best teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-n"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Twins + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/frost_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Hoang</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                the main damage carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative damage carry
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="mirael" enablePopover /> can
                  be used as an alternative{' '}
                  <strong>(Supreme+ Upgrade required)</strong>
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                act as buffers and healers
                <strong>(Supreme+ required)</strong>
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer
                <strong>(Mythic+ required)</strong>
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover />
                acts as buffer and tank
                <strong>(EX+10 required)</strong>
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> can
                  be used as alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                  can be used as an alternative, especially in the lightbearer
                  variant of this team
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Frostfall Spell" /> Takes advantage of The
                  Twins’ Ultimate spam.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team2" title={<>Team #2</>} className="with-bg">
            <h5>Team #2 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/frost_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>go4func</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                the main damage carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="mirael" enablePopover /> can
                  be used as an alternative{' '}
                  <strong>(Supreme+ Upgrade required)</strong>
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> as
                secondary damage carry
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer <strong>(Mythic+ required)</strong>
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and tank
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> can
                  be used as alternative <strong>(EX+10 required)</strong>
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Overcharge Spell" /> Used for the energy regen
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team3" title={<>Team #3</>} className="with-bg">
            <h5>Team #3- Alt Team (No Celehypo) </h5>
            <StaticImage
              src="../../../images/afk/dream_s/frost_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>TruongNguyen</strong> from AFK Analytica
              Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                the main damage carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="mirael" enablePopover /> can
                  be used as an alternative{' '}
                  <strong>(Supreme+ Upgrade required)</strong>
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage carry and buffer
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> can
                be used as alternative <strong>(EX+10 required)</strong>
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> acts
                as healer
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Frostfall Spell" /> Best damage with the
                  frequent ultimates
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Videos" />
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesSpike;

export const Head: React.FC = () => (
  <Seo
    title="Mirage Frostspike | AFK Journey | Prydwen Institute"
    description="Primal Lord Mirage Frostspike guide that gives you the best teams to use against the boss."
    game="afk"
  />
);
